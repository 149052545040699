import { storeConsts } from '../constants/store.constants';

export function storeReducer(state = {}, action) {
    switch (action.type) {
        case storeConsts.SUCCESS:
            return {
                type: 'store-success',
                message: action.message
            };
        case storeConsts.ERROR:
            return {
                type: 'store-error',
                message: action.message
            };
        default:
            return state
    }
}