import React from 'react';
import Moment from 'react-moment';
import {
    makeStyles,
    useTheme
} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, Hidden } from '@material-ui/core';
import clsx from 'clsx';
import Logo from './../../../assets/images/logo.png';
import { common } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    logo: {
        maxWidth: 160,
        top: '-30px',
        height: '84px',
        right: '15px',
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            right: '28px',
        }
    },
    rightSection: {
        paddingRight: '15px',
        [theme.breakpoints.up('sm')]: {
            paddingRight: '28px',
        }
    },
    copywrite: {
        bottom: 0,
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
        }
    },
    footer: {
        borderRadius: 0,
        background: '#0B0F17',
        color: common.white
    },
    link: {
        color: common.white,
        textDecoration: 'none',
        '&:hover': {
            color: common.white,
        },
        [theme.breakpoints.down('xs')]: {
            lineHeight: '1.75 !important',
            fontSize: '.6rem',
          }
    },
    footerLeft: {
        marginTop: '10px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '23px'
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '12px'
        }
    },
    footerPaddingLeft: {
        paddingLeft: '10px',
    },
    footerPaddingRight: {
        paddingRight: '10px',
    },
    mobFooterPadding: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: '5px'
        }
    },
    footerMR: {
        marginRight: 16,
        [theme.breakpoints.up('lg')]: {
            marginRight: 56,
        }
    },
    pointer: {
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            lineHeight: '1.75 !important',
            fontSize: '.6rem',
          }
    },
    zIndex: {
        zIndex: 1
    }
}));

const Sitemap = (classes, theme) => {
    return <Box component="div" ml={2} color="text.secondary" className={clsx(classes.footerMR, classes.zIndex)}>
        <Typography variant="h6">SITEMAP</Typography>
        <Typography variant="subtitle2"><Link to='/about-us' className={classes.link}>About Us</Link></Typography>
        <Typography variant="subtitle2"><Link to='/our-partners' className={classes.link}>Our Partners</Link></Typography>
        <Typography variant="subtitle2"><Link to='/contact' className={classes.link}>Contact</Link></Typography>
    </Box>
}

const Products = (classes, theme) => {
    const history = useHistory();

    const navigateTo = (url, external = true) => {
        if (external) {
            window.location.href = url;
        } else {
            history.push(url);
        }
    };

    return <>
        <Box component="div" ml={2} className={clsx(classes.footerMR, classes.zIndex)}>
            <Typography variant="h6">OUR PRODUCTS</Typography>
            <Typography variant="subtitle1">Healthcare</Typography>
            <Typography variant="subtitle2" onClick={() => { navigateTo('https://mdnius.com/') }} className={classes.pointer}>MDnius</Typography>
            {/* <Typography variant="subtitle2">Aikam Health</Typography> */}
            <Typography variant="subtitle2" onClick={() => { navigateTo('https://booking.mdnius.com/embassygrandvaccination') }} className={classes.pointer}>COVID-19 Vaccine Centre</Typography>
            <Typography variant="subtitle2" onClick={() => { navigateTo('https://embassygrand.mdnius.com/embassygrand') }} className={classes.pointer}>COVID-19 Testing Centre</Typography>
        </Box>
        <Box component="div" ml={2} mr={2} className={classes.zIndex}>
            <Typography variant="h6">&nbsp;</Typography>
            <Typography variant="subtitle1">Retail</Typography>
            <Typography variant="subtitle2" onClick={() => { navigateTo('https://solutions-stores.ca/') }} className={classes.pointer}>Solutions Stores</Typography>
            <Typography variant="subtitle2" onClick={() => { navigateTo('https://www.rigelclosets.com/') }} className={classes.pointer}>Rigel Closets</Typography>
            <Typography variant="subtitle2" onClick={() => { navigateTo('https://shopbundle.ca/') }} className={classes.pointer}>Shop Bundle</Typography>
        </Box>
    </>
}

const CompnayInfo = (classes) => {
    const history = useHistory();

    const navigateTo = (url, external = true) => {
        if (external) {
            window.location.href = url;
        } else {
            history.push(url);
        }
    };

    const CallNumber = () => {
        console.log('here');
        window.location.href = `tel:(905) 366-7363`;
    }

    const EmailTo = () => {
        window.location.href = `mailto:info@north60tech.com`;
    }
    return <Grid container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-end" className={clsx('position-relative h-100', classes.rightSection, classes.mobFooterPadding, classes.zIndex)}>
        <img src={Logo} alt="logo" className={clsx('img-fluid position-absolute', classes.logo)} onClick={() => { navigateTo('/', false) }} />
        <Typography variant="h6" style={{ fontSize: 6 }}>&nbsp;</Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle2" onClick={() => { CallNumber() }} className={clsx(classes.pointer)}>(905) 366-7363</Typography>
        <Typography variant="subtitle2" onClick={() => { EmailTo() }} className={clsx(classes.pointer)}>info@north60tech.com</Typography>
        <Typography variant="subtitle2" className={clsx(classes.pointer)} onClick={() => { navigateTo('https://maps.google.com/maps?q=1775%20Sismet%20Road') }} >1775 Sismet Road, Mississauga, ON</Typography>
    </Grid>
}

function Footer() {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <footer>
            <Paper className={clsx('position-relative', classes.footer)}>
                <Hidden xsDown>
                    <Grid container>
                        <Grid key={0} item xs={8} sm={8}>
                            <Grid container direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                className={classes.footerLeft}>
                                {Sitemap(classes, theme)}
                                {Products(classes, theme)}
                            </Grid>
                        </Grid>
                        <Grid key={1} item xs={4} sm={4}>
                            {CompnayInfo(classes)}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.copywrite}>
                        <Typography variant="subtitle2" component="span">Copyright <Moment format="YYYY"></Moment> North60 Technologies</Typography>
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <Grid container className={clsx('pt-2', classes.footerPaddingLeft, classes.footerPaddingRight)}>
                        <Grid key={0} item xs={4}>
                            <Grid container direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                className={classes.footerLeft}>
                                {Sitemap(classes, theme)}
                            </Grid>
                        </Grid>
                        <Grid key={1} item xs={8}>
                            {CompnayInfo(classes)}
                        </Grid>
                    </Grid>
                    <Grid container className={clsx('pt-3', classes.footerPaddingLeft, classes.footerPaddingRight)}>
                        <Grid key={1} item xs={12}>
                            <Grid container direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start">
                                {Products(classes, theme)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={clsx('pt-2 pb-1', classes.copywrite)}>
                        <Typography variant="subtitle2" component="span">Copyright <Moment format="YYYY"></Moment> North60 Technologies</Typography>
                    </Grid>
                </Hidden>
            </Paper>
        </footer >
    );
}

export default Footer;