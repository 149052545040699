import { Box, Grid, Hidden, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import EmailIcon from './../../assets/images/contact-us/EmailIcon.svg';
import CallIcon from './../../assets/images/contact-us/CallIcon.svg';
import FB from './../../assets/images/contact-us/FB.svg';
import Instagram from './../../assets/images/contact-us/Instagram.svg';
import LinkedIn from './../../assets/images/contact-us/LinkedIn.svg';
import Pinterest from './../../assets/images/contact-us/Pinterest.svg';
import Twitter from './../../assets/images/contact-us/Twitter.svg';
import { common } from '@material-ui/core/colors';
import RoomIcon from '@material-ui/icons/Room';
import CardMedia from '@material-ui/core/CardMedia';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        // backgroundImage: `url(${GrudgeEffect})`
        backgroundColor: '#D3D3D3',
        borderRadius: 0,
        border: 'none',
        color: common.white,
        paddingLeft: '10%',
        paddingRight: '10%',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '5%',
            paddingRight: '5%',
            height: 'auto',
        },
        [theme.breakpoints.up('xl')]: {
            alignItems: 'center',
            display: 'flex'
        }
    },
    mainGrid: {
        flexGrow: 1,
        height: '100%'
    },
    mapContainer: {
        textAlign: 'right',
        height: '100%',
        width: '300px',
        border: '1px solid #707070',
        float: 'right',
        [theme.breakpoints.up('xxl')]: {
            width: '565px',
            height: '604px !important',

        }
    },
    mapIframe: {
        height: "320px",
        width: "300px",
        [theme.breakpoints.up('xxl')]: {
            width: '564px',
            height: '600px !important',

        }
    },
    partnersTitle: {
        [theme.breakpoints.up('xxl')]: {
            fontSize:'3.5rem !important'
        }
    },
    leftSidePad: {
        paddingRight: '1.2rem'
    },
    rightSidePad: {
        paddingLeft: '1.2rem'
    },
    contactText: {
        color: '#27282E',
        lineHeight: 1,
        [theme.breakpoints.up('xxl')]: {
            fontSize:'28px'
        }
    },
    callIcon: {
        height: '20px',
        paddingRight: '.5rem',
        [theme.breakpoints.up('xxl')]: {
            height:'42px',
            width:'42px'
        }
    },
    emailIcon: {
        height: '14px',
        paddingRight: '.5rem',
        [theme.breakpoints.up('xxl')]: {
            width:'42px ',
            height:'29px'
        }
    },
    RoomIcon: {
        color: common.black,
        paddingRight: '0.1rem',
        fontSize: '1.75rem',
        margin: '0 0 0 -.25rem',
        [theme.breakpoints.up('xxl')]: {
            height:'54px',
            width:'38px'
        }
    },
    socialIcon: {
        height: '14px',
        marginRight: '14px',
        cursor: 'pointer',
        [theme.breakpoints.up('xxl')]: {
            height:'26px',
            width:'26px',
            marginRight:'30px',
            marginTop:'26px'
        }
    },
    contactDetails: {
        height: '320px',
        [theme.breakpoints.up('xxl')]: {
            height:'604px'
        }
    },
    headerText: {
        fontSize: '14px',
        [theme.breakpoints.up('xxl')]: {
            fontSize:'28px'
        }
    },
    CursorPoint: {
        cursor: 'pointer',
        [theme.breakpoints.up('xxl')]: {
            fontSize:'28px',
            textAlign:'left'
        }
    }
}));

function Contact() {
    const classes = useStyles();
    const history = useHistory();

    const navigateTo = (url, external = true) => {
        if (external) {
            window.location.href = url;
        } else {
            history.push(url);
        }
    };

    const CallNumber = () => {
        console.log('here');
        window.location.href = `tel:(905) 366-7363`;
    }

    const EmailTo = () => {
        window.location.href = `mailto:info@north60tech.com`;
    }

    return (
        <Paper className={classes.root} elevation={1} >
            <Hidden xsDown>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="flex-start"
                    className={clsx('pt-5', 'pb-5')}
                >
                    <Grid item xs={6} className={clsx('pt-5', 'pb-5', classes.leftSidePad)}>
                        <Box className={classes.mapContainer}>
                            <CardMedia
                                className={classes.mapIframe}
                                component="iframe"
                                title="Your Title"
                                src="https://maps.google.com/maps?q=1775%20Sismet%20Road&t=&z=13&ie=UTF8&iwloc=&output=embed"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6} className={clsx('pt-5', 'pb-5', 'pl-2', classes.rightSidePad)}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-evenly"
                            alignItems="flex-start"
                            className={classes.contactDetails}
                        >
                            <Grid item>
                                <Typography variant="h3" className={clsx('pb-2', classes.partnersTitle)}>Contact Us</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" className={clsx('pb-3', classes.contactText, classes.headerText)}>Say hello or ask us questions! We like<br></br> getting to know other people.</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" onClick={() => { CallNumber() }} className={clsx('pb-3', classes.contactText, classes.CursorPoint)}><img src={CallIcon} alt="Call" className={clsx('img-fluid', classes.callIcon)} />(905) 366-7363</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" onClick={() => { EmailTo() }} className={clsx('pb-3', classes.contactText, classes.CursorPoint)}><img src={EmailIcon} alt="Email" className={clsx('img-fluid', classes.emailIcon)} />info@north60tech.com</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" onClick={() => { navigateTo('https://maps.google.com/maps?q=1775%20Sismet%20Road') }} className={clsx('pb-3', classes.contactText, classes.CursorPoint)}><RoomIcon className={classes.RoomIcon} />1775 Sismet Road<br></br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mississauga, ON</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" className={clsx('pb-1', classes.contactText)}>Stay connected and follow us</Typography>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <img src={FB} alt="Facebook" className={clsx('img-fluid', classes.socialIcon)} onClick={() => { navigateTo('https://www.facebook.com/North60Technologies/') }} />
                                    <img src={Twitter} alt="Twitter" className={clsx('img-fluid', classes.socialIcon)} onClick={() => { navigateTo('https://twitter.com/north60tech?lang=en') }} />
                                    <img src={Instagram} alt="Instagram" className={clsx('img-fluid', classes.socialIcon)} onClick={() => { navigateTo('https://www.instagram.com/north60tech/?hl=en') }} />
                                    <img src={LinkedIn} alt="LinkedIn" className={clsx('img-fluid', classes.socialIcon)} onClick={() => { navigateTo('https://ca.linkedin.com/company/north60-tech') }} />
                                    {/* <img src={Pinterest} alt="Pinterest" className={clsx('img-fluid', classes.socialIcon)} onClick={() => { navigateTo('/', false) }} /> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden smUp>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={clsx('pt-5', 'pb-5')}
                >
                    <Grid item xs={12}>
                        <Grid container direction="column"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item>
                                <Typography variant="h3" className={clsx('pb-2', classes.partnersTitle)}>Contact Us</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" className={clsx('pb-3', classes.contactText, classes.headerText)}>Say hello or ask us questions! We like<br></br> getting to know other people.</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" onClick={() => { CallNumber() }} className={clsx('pb-3', classes.contactText)}><img src={CallIcon} alt="Call" className={clsx('img-fluid', classes.callIcon)} />(905) 366-7363</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" onClick={() => { EmailTo() }} className={clsx('pb-3', classes.contactText)}><img src={EmailIcon} alt="Email" className={clsx('img-fluid', classes.emailIcon)} />info@north60tech.com</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" onClick={() => { navigateTo('https://maps.google.com/maps?q=1775%20Sismet%20Road') }} className={clsx('pb-3', classes.contactText)}><RoomIcon className={classes.RoomIcon} />1775 Sismet Road<br></br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mississauga, ON</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Box className={classes.mapContainer}>
                                <CardMedia
                                    component="iframe"
                                    title="Your Title"
                                    height="320"
                                    width="150"
                                    src="https://maps.google.com/maps?q=1775%20Sismet%20Road&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="column"
                            justifyContent="center"
                            alignItems="center" className={clsx('pt-2')}>
                            <Typography variant="subtitle1" className={clsx('pb-1', classes.contactText)}>Stay connected and follow us</Typography>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                className={clsx('pt-2')}
                            >
                                <img src={FB} alt="Facebook" className={clsx('img-fluid', classes.socialIcon)} onClick={() => { navigateTo('https://www.facebook.com/North60Technologies/') }} />
                                <img src={Twitter} alt="Twitter" className={clsx('img-fluid', classes.socialIcon)} onClick={() => { navigateTo('https://twitter.com/north60tech?lang=en') }} />
                                <img src={Instagram} alt="Instagram" className={clsx('img-fluid', classes.socialIcon)} onClick={() => { navigateTo('https://www.instagram.com/north60tech/?hl=en') }} />
                                <img src={LinkedIn} alt="LinkedIn" className={clsx('img-fluid', classes.socialIcon)} onClick={() => { navigateTo('https://ca.linkedin.com/company/north60-tech') }} />
                                {/* <img src={Pinterest} alt="Pinterest" className={clsx('img-fluid', classes.socialIcon)} onClick={() => { navigateTo('/', false) }} /> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
        </Paper>
    );
}

export default withRouter(Contact)