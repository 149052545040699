import { red, common } from '@material-ui/core/colors';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpointValues = {
  xs: 0,
  xsm:300,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1600,
  xxl: 2500
};
const breakpoints = createBreakpoints({
  values: breakpointValues,
});

let theme = createTheme({
  breakpoints: {
    values: breakpointValues,
  },
  palette: {
    type: 'light',
    primary: {
      main: common.black,
      light: '#61dafb',
      dark: '#21a1c4',
    },
    secondary: {
      main: common.white,
      light: '#61dafb',
      dark: '#21a1c4',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: common.white,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          fontSize: '16px',
          lineHeight: '16px',
          [breakpoints.up('xl')]: {
            fontSize: '18px',
            lineHeight: '18px',
          }
        }
      }
    },
    MuiSvgIcon: {
      root: {
        marginTop: '3px',
        [breakpoints.up('xl')]: {
          width: '1.5em',
          height: '1.5em'
        }
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        color: common.white,
        fontFamily: 'DMSans',
        lineHeight: 1,
        fontWeight: 400,
        fontSize: '.9rem',
        padding: '0 !important',
        borderRadius: 0,
        minWidth: 'auto',
        [breakpoints.up('xl')]: {
          fontSize: '1.2rem',
          marginRight: '1rem !important'
        }
      },
    },
    MuiToolbar: {
      root: {
        [breakpoints.up('sm')]: {
          maxHeight: '3.6rem !important',
          minHeight: '3.6rem !important',
        },
        [breakpoints.up('xl')]: {
          maxHeight: '4.6rem !important',
          minHeight: '4.6rem !important',
        }
      },
      regular: {
        [breakpoints.down('xs')]: {
          maxHeight: '3.6rem !important',
          minHeight: '3.6rem !important',
        }
      },
      gutters: {
        [breakpoints.up('xl')]: {
          paddingLeft: '5rem',
          paddingRight: '5rem',
        }
      }
    },
    MuiList: {
      root: {
        padding: '0 !important'
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'DMSans',
        fontSize: '16x',
        lineHeight: 1,
        paddingBottom: '5px !important',
        paddingTop: '5px !important',
        minHeight: 'auto',
        '&:hover': {
          background: common.black,
          color: common.white
        },
        [breakpoints.up('xl')]: {
          fontSize: '1.5rem'
        }
      }
    },
    MuiCollapse: {
      root: {
        background: common.white,
        color: common.black
      }
    },
    Mui: {
      "&$expanded": {
        background: common.black,
        color: common.white
      }
    },
    MuiAccordion: {
      root: {
        margin: '0 !important',
        borderRadius: '0 !important',
        '&:hover': {
          background: common.black,
          color: common.white
        }
      }
    },
    MuiAccordionSummary: {
      root: {
        minHeight: '27px !important',
        fontFamily: 'DMSans',
        padding: '0 20px 0 10px !important',
        borderBottom: '1px solid #ccc'
      },
      content: {
        margin: '0 !important',
      },
    },
    MuiButtonBase: {
      root: {
        margin: '0 !important',
        paddingTop: '2px !important',
        paddingBottom: '2px !important'
      }
    },
    MuiIconButton: {
      root: {
        padding: '0 !important',
        color: 'inherit'
      }
    },
    MuiAccordionDetails: {
      root: {
        lineHeight: 1,

      }
    },
    MuiListItem: {
      root: {
        fontFamily: 'DMSans',
      },
      button: {
        lineHeight: 1,
        [breakpoints.down('xs')]: {
          justifyContent: 'flex-end',
        }
      },
      gutters: {
        paddingRight: '0px',
        [breakpoints.up('sm')]: {
          paddingRight: '16px',
        }
      }
    },
    MuiPaper: {
    }
  },
  typography: {
    subtitle1: {
      fontSize: 13,
      color: common.white,
      fontWeight: 600,
      fontFamily: 'DMSans',
    },
    subtitle2: {
      fontSize: '.8rem',
      color: common.white,
      fontFamily: 'DMSans',
      fontWeight: 400,
      [breakpoints.down('xs')]: {
        lineHeight: '1.75 !important',
        fontSize: '.7rem',
      }
    },
    h1: {
      fontFamily: 'Gothic',
      color: common.white,
      fontSize: '3rem !important',
      fontWeight: 400,
      lineHeight: 1,
      [breakpoints.down('xs')]: {
        fontSize: '1.6rem !important'
      }
    },
    h2: {
      fontFamily: 'Gothic',
      color: '#08A74F',
      fontSize: '2.8rem !important',
      fontWeight: 600,
      lineHeight: 1,
      [breakpoints.down('xs')]: {
        fontSize: '1.3rem !important'
      }
    },
    h3: {
      fontFamily: 'DMSans',
      color: common.black,
      fontWeight: 700,
      lineHeight: 1,
      fontSize: '2.3rem !important',
    },
    h5: {
      fontSize: '1.25rem',
      fontFamily: 'DMSans',
      lineHeight: 1,
      [breakpoints.down('xs')]: {
        fontSize: '.65rem !important'
      }
    },
    h6: {
      color: common.white,
      fontSize: '.85rem',
      fontWeight: 600,
      fontFamily: 'DMSans'
    },
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(',')
  }
});
theme = responsiveFontSizes(theme);
export default theme;