import { Grid, Hidden, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import brampton from './../../assets/images/partners/brampton.svg';
import ontario from './../../assets/images/partners/ontario.svg';
import Peel_Region from './../../assets/images/partners/Peel_Region.svg';
import MDnius_Logo from './../../assets/images/partners/MDnius_Logo.svg';
import Solutions_Stores_Logo_2x from './../../assets/images/partners/Solutions_Stores_Logo@2x.png';
import Rigel_Smart_Living_Logo_Black from './../../assets/images/partners/Rigel_Smart_Living_Logo_Black@2x.png';
import embassy_grand from './../../assets/images/partners/embassy_grand@2x.png';
import Sai_Dham_2x from './../../assets/images/partners/Sai_Dham@2x.png';
import Sewa_Canada_2x from './../../assets/images/partners/Sewa_Canada@2x.png';
import Oxygen_Langar_2x from './../../assets/images/partners/Oxygen_Langar@2x.png';
import { common } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        // backgroundImage: `url(${GrudgeEffect})`
        backgroundColor: '#D3D3D3',
        borderRadius: 0,
        border: 'none',
        color: common.white,
        paddingLeft: '10%',
        paddingRight: '10%',
        paddingTop: '2rem',
        paddingBottom: '2rem',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '7%',
            paddingRight: '5%',
            height: 'auto',
        },
        [theme.breakpoints.up('xl')]: {
            alignItems: 'center',
            display: 'flex'
        }
    },
    partnersTitle: {
        color: common.black,
        textAlign: 'center',
        [theme.breakpoints.up('xxl')]: {
            fontSize:'3.5rem !important'
        }
    },
    Brampton: {
        width: '13.5rem',
        [theme.breakpoints.up('xxl')]: {
            width:'25.75rem'
        }
    },
    RegionOfPeel: {
        width: '7.1rem',
        [theme.breakpoints.up('xxl')]: {
            width:'13.25rem'
        }
    },
    Ontario: {
        width: '10.75rem',
        [theme.breakpoints.up('xxl')]: {
            width:'19.25rem'
        }
    },
    mdnius: {
        width: '3.75rem',
        [theme.breakpoints.up('xxl')]: {
            width:'6.25rem'
        }
    },
    solution: {
        width: '13rem',
        paddingTop: '0.2rem',
        [theme.breakpoints.up('xxl')]: {
            width:'20rem'
        }
    },
    rigel: {
        width: '6.5rem',
        [theme.breakpoints.up('xxl')]: {
            width:'13rem'
        }
    },
    embassy: {
        width: '3rem',
        [theme.breakpoints.up('xxl')]: {
            width:'5.25rem'
        }
    },
    SaiDham: {
        width: '4.75rem',
        [theme.breakpoints.up('xxl')]: {
            width:'6.5rem'
        }
    },
    SewaCanada: {
        width: '15rem',
        [theme.breakpoints.up('xxl')]: {
            width:'23.5rem'
        }
    },
    OxygenLangar: {
        width: '6rem',
        [theme.breakpoints.up('xxl')]: {
            width:'9.75rem'
        }
    },
    logoCenter: {
        margin: '0 auto',
        display: 'flex'
    }
}));

function OurPartners() {
    const classes = useStyles();

    return (
        <Paper className={classes.root} elevation={1} >
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item className={'w-100'}>
                    <Typography variant="h3" className={clsx('mt-4 mb-4', classes.partnersTitle)}>Our Partners</Typography>
                </Grid>
                <Hidden xsDown>
                    <Grid item className={'w-100'}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            className={'pt-4 pb-4'}
                        >
                            <Grid item xs={4} className={clsx('d-flex', 'justify-content-center')}>
                                <img src={brampton} alt="Brampton" className={clsx('img-fluid', classes.Brampton)} />
                            </Grid>
                            <Grid item xs={4} className={clsx('d-flex', 'justify-content-center')}>
                                <img src={Peel_Region} alt="Region of peel" className={clsx('img-fluid', classes.RegionOfPeel)} />
                            </Grid>
                            <Grid item xs={4} className={clsx('d-flex', 'justify-content-center')}>
                                <img src={ontario} alt="Ontario" className={clsx('img-fluid', classes.Ontario)} />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            className={'pt-4 pb-4'}
                        >
                            <Grid item xs={3}>
                                <img src={MDnius_Logo} alt="MDnius" className={clsx('img-fluid', classes.mdnius, classes.logoCenter)} />
                            </Grid>
                            <Grid item xs={3}>
                                <img src={Solutions_Stores_Logo_2x} alt="Solutions Stores" className={clsx('img-fluid', classes.solution, classes.logoCenter)} />
                            </Grid>
                            <Grid item xs={3}>
                                <img src={Rigel_Smart_Living_Logo_Black} alt="Rigel Smart Living" className={clsx('img-fluid', classes.rigel, classes.logoCenter)} />
                            </Grid>
                            <Grid item xs={3}>
                                <img src={embassy_grand} alt="Embassy Grand" className={clsx('img-fluid', classes.embassy, classes.logoCenter)} />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            className={'pt-4 pb-4'}
                        >
                            <Grid item xs={4} className={clsx('d-flex', 'justify-content-end')}>
                                <img src={Sai_Dham_2x} alt="Sai Dham" className={clsx('img-fluid', classes.SaiDham)} />
                            </Grid>
                            <Grid item xs={4}>
                                <img src={Sewa_Canada_2x} alt="Sewa Canada" className={clsx('img-fluid', classes.SewaCanada, classes.logoCenter)} />
                            </Grid>
                            <Grid item xs={4}>
                                <img src={Oxygen_Langar_2x} alt="Oxygen Langar" className={clsx('img-fluid', 'float-left', classes.OxygenLangar)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <Grid container className={'w-100'}>
                        <Grid item xs={6} className={clsx('d-flex', 'justify-content-center')}>
                            <Grid container>
                                <Grid item xs={12} className={clsx('d-flex', 'justify-content-center', 'pb-5')}>
                                    <img src={brampton} alt="Brampton" className={clsx('img-fluid', classes.Brampton)} />
                                </Grid>
                                <Grid item xs={12} className={clsx('d-flex', 'justify-content-center', 'pb-5')}>
                                    <img src={ontario} alt="Ontario" className={clsx('img-fluid', classes.Ontario)} />
                                </Grid>
                                <Grid item xs={12} className={clsx('d-flex', 'justify-content-center', 'pb-5')}>
                                    <img src={Solutions_Stores_Logo_2x} alt="Solutions Stores" className={clsx('img-fluid', classes.solution, classes.logoCenter)} />
                                </Grid>
                                <Grid item xs={12} className={clsx('d-flex', 'justify-content-center', 'pb-5')}>
                                    <img src={embassy_grand} alt="Embassy Grand" className={clsx('img-fluid', classes.embassy, classes.logoCenter)} />
                                </Grid>
                                <Grid item xs={12} className={clsx('d-flex', 'justify-content-center', 'pb-5')}>
                                    <img src={Sewa_Canada_2x} alt="Sewa Canada" className={clsx('img-fluid', classes.SewaCanada, classes.logoCenter)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className={clsx('d-flex', 'justify-content-center')}>
                            <Grid container>
                                <Grid item xs={12} className={clsx('d-flex', 'justify-content-center', 'pb-5')}>
                                    <img src={Peel_Region} alt="Region of peel" className={clsx('img-fluid', classes.RegionOfPeel)} />
                                </Grid>
                                <Grid item xs={12} className={clsx('d-flex', 'justify-content-center', 'pb-5')}>
                                    <img src={MDnius_Logo} alt="MDnius" className={clsx('img-fluid', classes.mdnius, classes.logoCenter)} />
                                </Grid>
                                <Grid item xs={12} className={clsx('d-flex', 'justify-content-center', 'pb-5')}>
                                    <img src={Rigel_Smart_Living_Logo_Black} alt="Rigel Smart Living" className={clsx('img-fluid', classes.rigel, classes.logoCenter)} />
                                </Grid>
                                <Grid item xs={12} className={clsx('d-flex', 'justify-content-center', 'pb-5')}>
                                    <img src={Sai_Dham_2x} alt="Sai Dham" className={clsx('img-fluid', classes.SaiDham)} />
                                </Grid>
                                <Grid item xs={12} className={clsx('d-flex', 'justify-content-center', 'pb-5')}>
                                    <img src={Oxygen_Langar_2x} alt="Oxygen Langar" className={clsx('img-fluid', 'float-left', classes.OxygenLangar)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
        </Paper>
    );
}

export default withRouter(OurPartners)