import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import {
    makeStyles,
    useTheme
} from '@material-ui/core/styles';
import {
    AppBar, Toolbar, List, ListItem,
    IconButton, Box, Hidden
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Logo from './../../../assets/images/logo.png';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { common } from '@material-ui/core/colors';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 999
    },
    logo: {
        maxWidth: '9rem',
        top: 0,
        height: '4.75rem',
        left: 0,
        cursor: 'pointer',
        [theme.breakpoints.up('xl')]: {
            height: '6.75rem',
            maxWidth: '12rem',
        }
    },
    list: {
        width: '100%',
    },
    padding: {
        paddingRight: 30,
        cursor: "pointer",
    },

    sideBarIcon: {
        padding: 0,
        color: "white",
        cursor: "pointer",
    },
    menu: {
        padding: '0 32px'
    },
    activeMenu: {
        fontWeight: 600,
        borderBottom: '2px solid #fff'
    },
    mainMenuItem: {
        paddingLeft: '25px',
        paddingRight: '25px',
    },
    listItem: {
        paddingRight: '25px'
    },
    white: {
        color: common.white
    },
    link: {
        color: common.white,
        textDecoration: 'none',
        '&:hover': {
            color: common.white,
        },
    },
    mobMenu: {
        boxShadow: 'none',
        [theme.breakpoints.down('xs')]: {
            borderBottom: '1px solid #ccc'
        }
    },
    noShadow: {
        boxShadow: 'none',
    }
}));

const Header = () => {
    const [open, setOpen] = useState([false, false, false]);
    const [expanded, setExpanded] = useState(false);
    const history = useHistory();

    let anchorRef = [];
    anchorRef.push(useRef(null));
    anchorRef.push(useRef(null));
    anchorRef.push(useRef(null));
    let prevOpen = [];
    prevOpen.push(useRef(open[0]));
    prevOpen.push(useRef(open[1]));
    prevOpen.push(useRef(open[2]));

    const classes = useStyles();
    const theme = useTheme();

    const navigateTo = (url, external = true) => {
        if (external) {
            window.location.href = url;
        } else {
            history.push(url);
        }
    };

    const handleToggle = (index) => {
        setOpen((prevOpen) => ({
            ...prevOpen,
            [index]: !prevOpen[index]
        }));
        if (index !== 2 && window.innerWidth > 600) {
            const idx = 2;
            setOpen((prevOpen) => ({
                ...prevOpen,
                [idx]: false
            }));
        }
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClose = (event, index) => {
        if (anchorRef[index] && anchorRef[index].current && anchorRef[index].current.contains(event.target)) {
            return;
        }
        setOpen((prevOpen) => ({
            ...prevOpen,
            [index]: false
        }));
    };

    function handleListKeyDown(event, index) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen((prevOpen) => ({
                ...prevOpen,
                [index]: false
            }));
        }
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            setOpen((prevOpen, index = 2) => ({
                ...prevOpen,
                [index]: false
            }));
        });
        // if (prevOpen[0].current === true && open[0] === false) {
        //     anchorRef[0].current.focus();
        // }
        // prevOpen[0].current = open[0];
    }, [open])

    return (
        <>
            <AppBar
                className={classes.root}
            >
                <Toolbar>
                    <Box display='flex' flexGrow={1}>
                        <img src={Logo} alt="logo" className={clsx('img-fluid position-absolute top-0 ', classes.logo)} onClick={() => { navigateTo('/', false) }} />
                    </Box>
                    <Box className={'d-flex align-items-center'}>
                        <Hidden xsDown>
                            <Box component="span" key="menu-1" className={classes.menu}>
                                <Button
                                    disableRipple={true}
                                    ref={anchorRef[0]}
                                    aria-controls={open[0] ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={() => handleToggle(0)}
                                    onMouseLeave={() => handleToggle(0)}
                                    className={open[0] ? classes.activeMenu : ''}
                                >Healthcare</Button>
                                <Popper open={open[0]} anchorEl={anchorRef[0].current} role={undefined} onMouseEnter={() => handleToggle(0)}
                                    onMouseLeave={() => handleToggle(0)} transition disablePortal
                                    style={{
                                        paddingTop: window.innerWidth > 1600 ? '1.7rem' : '1.3rem',
                                    }}
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper square>
                                                <ClickAwayListener onClickAway={() => handleClose(0)}>
                                                    <MenuList padding={0} id="menu-list-grow" onKeyDown={() => handleListKeyDown(0)}>
                                                        <MenuItem onClick={() => { handleClose(0); navigateTo('https://mdnius.com/') }}>MDnius</MenuItem>
                                                        {/* <MenuItem onClick={() => {handleClose(0); navigateTo('/about-us') }}>Aikam Health</MenuItem> */}
                                                        <MenuItem onClick={() => { handleClose(0); navigateTo('https://booking.mdnius.com/embassygrandvaccination') }}>COVID-19 Vaccine Centre</MenuItem>
                                                        <MenuItem onClick={() => { handleClose(0); navigateTo('https://embassygrand.mdnius.com/embassygrand/') }}>COVID-19 Testing Centre</MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Box>
                        </Hidden>
                        <Hidden xsDown>
                            <Box component="span" key="menu-2" className={classes.menu}>
                                <Button
                                    disableRipple={true}
                                    ref={anchorRef[1]}
                                    aria-controls={open[1] ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={() => handleToggle(1)}
                                    onMouseLeave={() => handleToggle(1)}
                                    className={open[1] ? classes.activeMenu : ''}
                                >Retail</Button>
                                <Popper open={open[1]} anchorEl={anchorRef[1].current} role={undefined} onMouseEnter={() => handleToggle(1)}
                                    onMouseLeave={() => handleToggle(1)} transition disablePortal
                                    style={{ paddingTop: window.innerWidth > 1600 ? '1.7rem' : '1.3rem', }}>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper square>
                                                <ClickAwayListener onClickAway={() => handleClose(1)}>
                                                    <MenuList id="menu-list-grow" onKeyDown={() => handleListKeyDown(1)}>
                                                        <MenuItem onClick={() => { handleClose(1); navigateTo('https://solutions-stores.ca/') }}>Solutions Stores</MenuItem>
                                                        <MenuItem onClick={() => { handleClose(1); navigateTo('https://www.rigelclosets.com/') }}>Rigel Closets</MenuItem>
                                                        <MenuItem onClick={() => { handleClose(1); navigateTo('https://shopbundle.ca/') }}>Shop Bundle</MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Box>
                        </Hidden>
                        <Box component="span" key="menu-3" style={{ paddingRight: window.innerWidth > 600 && theme.spacing(.5) }}>
                            {!open[2] && <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => { handleToggle(2) }}
                                edge="start"
                            >
                                <MenuIcon fontSize="large" />
                            </IconButton>}
                            {open[2] &&
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={() => { handleToggle(2) }}
                                    edge="start"
                                >
                                    <CloseIcon fontSize="large" />
                                </IconButton>}
                            <Popper open={open[2]} anchorEl={anchorRef[2].current} role={undefined}
                                transition disablePortal style={{ right: (window.innerWidth > 600 ? 20 : 0), top: window.innerWidth > 1600 ? '4.6rem' : '3.6rem', left: 'unset' }}>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper square>
                                            <ClickAwayListener onClickAway={() => handleClose(2)}>
                                                <div>
                                                    <Hidden smUp>
                                                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={clsx('p-0', classes.noShadow)}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon className={expanded === 'panel1' ? classes.white : ''} />}
                                                                aria-controls="panel1bh-content"
                                                                id="panel1bh-header"
                                                                className="p-0 m-0"
                                                            >
                                                                <List className={classes.list}>
                                                                    <ListItem key={1} button> Healthcare </ListItem>
                                                                </List>
                                                            </AccordionSummary>
                                                            <AccordionDetails className="p-0 m-0">
                                                                <List className={classes.list}>
                                                                    <ListItem key={0} button className={clsx(classes.listItem, classes.mobMenu)} onClick={() => { setOpen(2); navigateTo('https://mdnius.com/') }}> MDnius </ListItem>
                                                                    <ListItem key={1} button className={clsx(classes.listItem, classes.mobMenu)} onClick={() => { setOpen(2); navigateTo('https://booking.mdnius.com/embassygrandvaccination') }}> COVID-19 Vaccine Centre </ListItem>
                                                                    <ListItem key={2} button className={clsx(classes.listItem, classes.mobMenu)} onClick={() => { setOpen(2); navigateTo('https://embassygrand.mdnius.com/embassygrand/') }}> COVID-19 Testing Centre </ListItem>
                                                                </List>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className={clsx('p-0', classes.noShadow)}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon className={expanded === 'panel2' ? classes.white : ''} />}
                                                                aria-controls="panel2bh-content"
                                                                id="panel2bh-header"
                                                                className="p-0 m-0"
                                                            >
                                                                <List className={classes.list}>
                                                                    <ListItem key={1} button> Retail </ListItem>
                                                                </List>
                                                            </AccordionSummary>
                                                            <AccordionDetails className="p-0">
                                                                <List className={classes.list}>
                                                                    <ListItem key={0} button className={clsx(classes.listItem, classes.mobMenu)} onClick={() => { setOpen(2); navigateTo('https://solutions-stores.ca/') }}> Solutions Stores </ListItem>
                                                                    <ListItem key={1} button className={clsx(classes.listItem, classes.mobMenu)} onClick={() => { setOpen(2); navigateTo('https://www.rigelclosets.com/') }}> Rigel Closets </ListItem>
                                                                    <ListItem key={2} button className={clsx(classes.listItem, classes.mobMenu)} onClick={() => { setOpen(2); navigateTo('https://shopbundle.ca/') }}> Shop Bundle </ListItem>
                                                                </List>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Hidden>
                                                    <MenuList autoFocusItem={open[1]} id="menu-list-grow" onKeyDown={() => handleListKeyDown(2)}>
                                                        <MenuItem className={clsx(classes.mainMenuItem, classes.mobMenu)} onClick={() => { setOpen(2); navigateTo('/about-us', false) }}>About Us</MenuItem>
                                                        <MenuItem className={clsx(classes.mainMenuItem, classes.mobMenu)} onClick={() => { setOpen(2); navigateTo('/our-partners', false) }}>Our Partners</MenuItem>
                                                        <MenuItem className={clsx(classes.mainMenuItem, classes.mobMenu)} onClick={() => { setOpen(2); navigateTo('/contact', false) }}>Contact</MenuItem>
                                                    </MenuList>
                                                </div>

                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            {/* Hack to make the AppBar static */}
            <Toolbar></Toolbar>
        </>
    )
}

export default Header;
