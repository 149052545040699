import { Box, Grid, Hidden, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import GrudgeEffect from './../../assets/images/homepage/desktop/Web_Grudge_Effect.svg';
import Mobile_Grudge_Effect from './../../assets/images/homepage/mobile/Mobile_Grudge_Effect.svg';
// import mobileImageURL from './../../assets/images/homepage/iPhone_12_Mockup_v2.png';
import iPhone_12_Mockup_v2_3x from './../../assets/images/homepage/desktop/Web_Hero_Image@3x.png';
import Mob_iPhone_12_Mockup_v2_1x from './../../assets/images/homepage/mobile/Mobile_Hero_Image@1x.png';
import n60_logo from './../../assets/images/homepage/n60_logo.png';
import MDnius_QR_Code from './../../assets/images/homepage/MDnius_QR_Code@2x.png';
import AppleAppLogo from './../../assets/images/homepage/apple_store_badge@2x.png';
import GoogleAppLogo from './../../assets/images/homepage/google_play_badge@2x.png';
// import mobileImageMobURL from './../../assets/images/homepage/mobile/iPhone_12_Mockup_v2.png';
import { common } from '@material-ui/core/colors';
import { useHistory } from "react-router-dom";
import {
    isTablet
} from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        backgroundImage: `url(${GrudgeEffect})`,
        backgroundSize: 'cover',
        backgroundColor: '#0B0F17',
        borderRadius: 0,
        border: 'none',
        color: common.white,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 'auto',
        maxHeight: isTablet ? 'auto' : 780,
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 15,
            paddingRight: 15,
            backgroundImage: `url(${Mobile_Grudge_Effect})`,
            paddingBottom: 0
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: isTablet ? 'auto' : window.innerHeight - 70 + 'px'
        },
    },
    mainGrid: {
        flexGrow: 1,
        height: '100%'
    },
    leftSection: {
        width: 305,
        [theme.breakpoints.up('xl')]: {
            width: 400,
        },
    },
    rightSection: {
        flex: 1,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('xl')]: {
            display: 'flex',
            alignItems: 'center'
        },
    },
    mobileImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    logo: {
        maxWidth: 160,
        top: '-36px',
        height: '84px',
        right: '15px'
    },
    iphoneContainer: {
        width: '100%',
        height: '690px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
             height: '435px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '360px',
        }
    },
    iphone: {
        width: '100%',
        height: '80%',
        backgroundImage: `url(${iPhone_12_Mockup_v2_3x})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        [theme.breakpoints.down('xs')]: {
            marginTop: '0',
            backgroundImage: `url(${Mob_iPhone_12_Mockup_v2_1x})`,
            marginBottom: '0px',
            height: '100%',
        },
        [theme.breakpoints.up('xl')]: {
            height: '100%',
        },
        [theme.breakpoints.up('xxl')]: {
            height: '750px',
        }
    },
    n60_logo: {
        height: '2rem',
        marginTop: '-4px',
        [theme.breakpoints.down('xs')]: {
            height: '1.9rem'
        }
    },
    qr: {
        width: '100%'
    },
    bottomLeftImages: {
        marginTop: '50px'
    },
    qrContainer: {
        width: '110px',
        height: '100%',
        cursor: 'pointer'
    },
    appLogoContainer: {
        width: '188px',
        marginLeft: '6px',
        height: '92px',
    },
    appleLogo: {
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    googlePlayLogo: {
        marginTop: '6px',
        width: '90%',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: '0',
            marginLeft: '3px',
            height: '27px'
        }
    },
    mobLogos: {
        width: '160px',
        margin: '0 auto'
    },
    mobiPhone: {
        // height: '250px'
    }
}));

function Homepage() {
    const classes = useStyles();
    const history = useHistory();

    const navigateTo = (url, external = true) => {
        if (external) {
            window.location.href = url;
        } else {
            history.push(url);
        }
    };

    return (
        <Paper className={classes.root} >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.mainGrid}>
                <Hidden mdDown>
                    <Grid item className={classes.leftSection}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            className={'w-100'}
                        >
                            <Grid item className={'w-100'}>
                                <Typography variant="h1">POWERED BY</Typography>
                            </Grid>
                            <Grid item className={'w-100'}>
                                <Typography variant="h2">NORTH<img src={n60_logo} alt="North60Tech" className={classes.n60_logo}></img>TECH</Typography>
                            </Grid>
                            <Grid item className={clsx('w-100', classes.bottomLeftImages)}>
                                <Typography variant="h5" className={'w-100 mb-2'}>Download the <b>free MDnius app!</b></Typography>
                                <Grid container className={'w-100'}>
                                    <Grid item className={classes.qrContainer}>
                                        <Box>
                                            <img src={MDnius_QR_Code} alt="MDnius QR Store" className={classes.qr}></img>
                                        </Box>
                                    </Grid>
                                    <Grid item className={classes.appLogoContainer}>
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid item xs={12}>
                                                <Box>
                                                    <img src={AppleAppLogo} alt="Apple Store" className={clsx(classes.qr, classes.appleLogo)} onClick={() => { navigateTo('https://apps.apple.com/ca/app/mdnius/id1126407912') }}></img>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box className={'d-flex justify-content-center'}>
                                                    <img src={GoogleAppLogo} alt="Google Play Store" className={clsx(classes.qr, classes.googlePlayLogo)} onClick={() => { navigateTo('https://play.google.com/store/apps/details?id=com.north60tech.dok&hl=en_IN&gl=US') }}></img>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden mdDown>
                    <Grid item className={classes.rightSection}>
                        <Box className={classes.iphoneContainer}>
                            <Box className={classes.iphone}></Box>
                        </Box>
                    </Grid>
                </Hidden>
                <Hidden lgUp>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item className={'w-100  mt-5'}>
                            <Typography variant="h1" className={'text-center mb-1'}>POWERED BY</Typography>
                        </Grid>
                        <Grid item className={'w-100'}>
                            <Typography variant="h2" className={'text-center'}>NORTH<img src={n60_logo} alt="North60Tech" className={classes.n60_logo}></img>TECH</Typography>
                        </Grid>
                        <Grid item className={clsx('w-100')}>
                            <Typography variant="h5" className={'w-100 mb-2 mt-3 text-center'}>Download the <b>free MDnius app!</b></Typography>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                className={classes.mobLogos}
                            >
                                <Grid item xs={6}>
                                    <Box>
                                        <img src={AppleAppLogo} alt="Apple Store" className={clsx(classes.qr, classes.appleLogo)} onClick={() => { navigateTo('https://apps.apple.com/ca/app/mdnius/id1126407912') }}></img>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box>
                                        <img src={GoogleAppLogo} alt="Google Play Store" className={clsx(classes.qr, classes.googlePlayLogo)} onClick={() => { navigateTo('https://play.google.com/store/apps/details?id=com.north60tech.dok&hl=en_IN&gl=US') }}></img>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={clsx('w-100', { 'mb-4': (window.innerHeight > 600) }, classes.mobiPhone)}>
                            <Box className={classes.iphoneContainer}>
                                <Box className={classes.iphone}></Box>
                            </Box>
                        </Grid>
                        {/* <Grid className={clsx('w-100', classes.iphoneContainer)}>
                            <Box className={classes.iphone}></Box>
                        </Grid> */}
                    </Grid>
                </Hidden>
            </Grid>
        </Paper>
    );
}

export default withRouter(Homepage)