import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Routes from '../../navigation/routes';
// import API from '../../services/api';
import theme from '../../styles/theme';

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
