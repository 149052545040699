import { Grid, Hidden, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import Web_About_Us_1x from './../../assets/images/about-us/desktop/Web_About_us@1x.png';
import Mobile_About_Us_1x from './../../assets/images/about-us/mobile/Mobile_About_Us@1x.png';
import { common } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        backgroundColor: '#D3D3D3',
        borderRadius: 0,
        border: 'none',
        color: common.white,
        paddingLeft: '10%',
        paddingRight: '10%',
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        },
        [theme.breakpoints.up('xl')]: {
            alignItems: 'center',
            display: 'flex'
        }
    },
    mainGrid: {
        flexGrow: 1,
        height: '100%'
    },
    leftSection: {
        padding: '0 3rem 0 3rem',
        [theme.breakpoints.down('xs')]: {
            margin: '0',
            padding: '0 0 25px 0',
        }
    },
    rightSection: {
        flex: 1,
        height: '100%'
    },
    mobileImage: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        maxHeight: '480px',
        [theme.breakpoints.up('xxl')]: {
            height: '100%',
            width: '100%',
            maxHeight: '1170px'
        }
    },
    logo: {
        maxWidth: 160,
        top: '-36px',
        height: '84px',
        right: '15px'
    },
    aboutUsTitle: {
        color: common.black,
        fontFamily: 'DMSans',
        marginBottom: 10,
        fontSize: '2.5rem !important',
        fontWeight: 700,
        [theme.breakpoints.up('xxl')]: {
            fontSize: '4.5rem !important'
        }
    },
    aboutUsDetails: {
        color: common.black,
        fontSize: 13,
        lineHeight: '1 !important',
        [theme.breakpoints.up('xxl')]: {
            fontSize: 34,
            lineHeight: '2 !important',
        }
    }
}));

function AboutUs() {
    const classes = useStyles();

    return (
        <Paper className={classes.root} elevation={1} >
            <Hidden xsDown>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.mainGrid}>
                    <Grid item xs={6} className={classes.leftSection}>
                        <Typography variant="h2" className={classes.aboutUsTitle}>About Us</Typography>
                        <Typography variant="subtitle2" component="span" className={classes.aboutUsDetails}>N60 is an incubator committed to creating sustainable technologies bringing innovative digital solutions by understanding and disrupting the status quo. It is best known for being able to pivot with quick turnaround times to provide eco-friendly operating systems. Mastering digital solutions in retail, healthcare, and various verticals, North60Tech is fundamental in providing businesses with simplified workflows to increase productivity and efficiency while maintaining safety and security measures.</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <img src={Web_About_Us_1x} alt="Iphone" className={clsx('img-fluid', classes.mobileImage)} />
                    </Grid>
                </Grid>
            </Hidden>

            <Hidden smUp>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Grid item xs={12}>
                        <img src={Mobile_About_Us_1x} alt="Iphone" className={clsx('img-fluid mt-3', classes.mobileImage)} />
                    </Grid>
                    <Grid item xs={12} className={classes.leftSection}>
                        <Typography variant="h2" className={classes.aboutUsTitle}>About Us</Typography>
                        <Typography variant="subtitle2" component="span" className={classes.aboutUsDetails}>N60 is an incubator committed to creating sustainable technologies bringing innovative digital solutions by understanding and disrupting the status quo. It is best known for being able to pivot with quick turnaround times to provide eco-friendly operating systems. Mastering digital solutions in retail, healthcare, and various verticals, North60Tech is fundamental in providing businesses with simplified workflows to increase productivity and efficiency while maintaining safety and security measures.</Typography>
                    </Grid>
                </Grid>
            </Hidden>
        </Paper>
    );
}

export default withRouter(AboutUs)