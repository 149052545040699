import { Route, HashRouter, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from '../components/misc/ScrollTop';
import Header from '../components/common/header/Header';
import Footer from '../components/common/footer/Footer';
import Homepage from '../pages/homepage/Homepage';
import { Box } from '@material-ui/core';
import AboutUs from '../pages/about-us/AboutUs';
import OurPartners from '../pages/our-partners/OurPartners';
import Contact from '../pages/contact/Contact';
import {
  makeStyles
} from '@material-ui/core/styles';
import {
  isTablet
} from "react-device-detect";


const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    minHeight: isTablet ? `calc(100vh - 10rem)` :  '650px',
    [theme.breakpoints.up('sm')]: {
      height: isTablet ? `calc(100vh - 10rem)` :  window.innerHeight - 70 + 'px'
    },
    [theme.breakpoints.up('xl')]: {
      height: (window.innerHeight - 70) > 2560 ? '2560px' : window.innerHeight - 70 + 'px'
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto'
    }
  },
}));

const Routes = () => {
  const classes = useStyles();

  return (
    <HashRouter>
      <ScrollToTop>
        <Header />
        <Box className={classes.root}>
          <Switch>
            <Route exact path='/' component={Homepage} />
            <Route exact path='/about-us' component={AboutUs} />
            <Route exact path='/our-partners' component={OurPartners} />
            <Route exact path='/contact' component={Contact} />
            <Redirect from="*" to="/" />
          </Switch>
        </Box>
        <Footer />
      </ScrollToTop>
    </HashRouter>
  )
}

export default Routes;